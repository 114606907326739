import { Component, OnInit, Input } from '@angular/core';
import { SettingsService } from '../settings.service';
import { HttpClient } from '@angular/common/http';
import { ProjectParamsService } from '../project-params.service';
import { Renderer2 } from '@angular/core';

@Component({
  selector: 'publications-widget',
  templateUrl: './publicationswidget.component.html',
  styleUrls: ['./publicationswidget.component.scss']
})
export class PublicationswidgetComponent implements OnInit {
  allPublications: any;
  apiUrl: string;
  publications: any = [];
  groupedPublicationsByYear: any = [];
  mainAuthor: any;
  bColor7: any;
  fColor7: any;
  bColor8: any;
  fColor8: any;

  constructor(
    private settings: SettingsService,
    private projectParams: ProjectParamsService,
    private http: HttpClient,
    private renderer: Renderer2
  ) { }

  @Input() length: number; // De lengte van de ingekorte lijst voor de samenvatting
  @Input() summary: boolean;

  ngOnInit() {
    this.apiUrl = this.settings.getSetting('apiUrl');
    const url: string = `${this.apiUrl}/publications`;

    this.http.get(url).subscribe(
      (data: any) => {
        this.publications = data;
        this.publications = this.publications.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

        const site = this.settings.getSetting('defaultSite');
        this.projectParams.getParams(site).then(settings => {
          this.mainAuthor = settings['pubsMainAutor'];
          this.bColor7 = settings['bColor7'];
          this.fColor7 = settings['fColor7'];
          this.bColor8 = settings['bColor8'];
          this.fColor8 = settings['fColor8'];
        });

        this.publications = this.publications.map(publication => {
          publication.authors = publication.authors ? publication.authors.split(',') : [];
          publication.citation = this.makeCitation(publication);

          publication.doilink = `https://doi.org/${publication.citation.text}`.replace('doi: ', '');

          publication.abstractUrl = publication.abstractUrl && publication.abstractUrl.length > 0
            ? publication.abstractUrl
            : undefined;

          return publication;
        });

        if (this.publications.length > 0) {
          this.publications = this.publications.slice(0, this.publications.length - 1);
        }

        if (this.summary && this.length) {
          // Trim de lijst voor de summary-modus op basis van de ingevoerde lengte
          this.publications = this.publications.slice(0, this.length);
        }

        // Gebruik een aparte variabele voor gegroepeerde jaren
        this.groupPublicationsByYear();

        this.allPublications = this.publications;

        // Voeg script dynamisch toe via Angular Renderer2 in plaats van jQuery
        const script = this.renderer.createElement('script');
        script.src = 'https://d1bxh8uas1mnw7.cloudfront.net/assets/embed.js';
        this.renderer.appendChild(document.body, script);
      },
      err => {
        console.log('Error fetching publications:', err);
      }
    );
  }

  // Nieuwe methode om publicaties te groeperen per jaar
  groupPublicationsByYear() {
    let publicationYears = [];
    let latestYear;

    this.publications.forEach(publication => {
      const year = new Date(publication.date).getFullYear(); // Zorg ervoor dat je het jaar correct verkrijgt

      if (latestYear !== year) {
        latestYear = year;
        publicationYears.push({ year: year, publications: [] });
      }

      let yearGroup = publicationYears.find(y => y.year === year);
      if (yearGroup) {
        yearGroup.publications.push(publication);
      }
    });

    // Bewaar de gegroepeerde publicaties in een aparte variabele
    this.groupedPublicationsByYear = publicationYears;
  }

  makeCitation(publication) {
    let doi = this.selector(publication.articleids, 'idtype', 'doi', 'value') || publication.elocationid;
    let link = publication.attachment
      ? `https://www.engelbeelab.com/show/${publication.attachment}`
      : publication.uid
        ? `https://www.ncbi.nlm.nih.gov/pubmed/${publication.uid}`
        : 'https://www.ncbi.nlm.nih.gov/pubmed/';
    return { text: doi, link: link };
  }

  selector(o, key, val, field) {
    let r: string;
    if (Array.isArray(o)) {
      for (let item of o) {
        if (item[key] === val) {
          r = item[field];
        }
      }
    }
    return r;
  }
}