// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.page {
  background-color: rgba(255, 255, 255, 0.9);
  padding: 50px;
  width: 100%;
  margin-left: auto;
  margin-right: auto;
}

.container {
  padding-top: 160px;
  padding-bottom: 100px;
}

.container-fluid {
  min-height: 100%;
  background-size: cover;
}

img {
  width: 100%;
  padding-bottom: 10px;
}

@media screen and (max-width: 575px) {
  .container {
    padding: 0;
    padding-top: 100px;
    padding-bottom: 100px;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/page/page.component.scss"],"names":[],"mappings":"AAAA;EACE,0CAAA;EACA,aAAA;EACA,WAAA;EACA,iBAAA;EACA,kBAAA;AACF;;AAEA;EACE,kBAAA;EACA,qBAAA;AACF;;AAEA;EACE,gBAAA;EACA,sBAAA;AACF;;AAKA;EACE,WAAA;EACA,oBAAA;AAFF;;AAKA;EACE;IACE,UAAA;IACA,kBAAA;IACA,qBAAA;EAFF;AACF","sourcesContent":[".page {\r\n  background-color: rgba(255,255,255,.9);\r\n  padding: 50px;\r\n  width: 100%;\r\n  margin-left: auto;\r\n  margin-right: auto;\r\n}\r\n\r\n.container {\r\n  padding-top: 160px;\r\n  padding-bottom: 100px;\r\n}\r\n\r\n.container-fluid {\r\n  min-height: 100%;\r\n  background-size: cover;\r\n \r\n}\r\n\r\n\r\n\r\nimg {\r\n  width: 100%;\r\n  padding-bottom: 10px;\r\n}\r\n\r\n@media screen and (max-width:575px) {\r\n  .container {\r\n    padding: 0;\r\n    padding-top: 100px;\r\n    padding-bottom: 100px;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
