import { Injectable } from "@angular/core";
import axios from "axios";
import { SettingsService } from "./settings.service";

@Injectable({
  providedIn: "root"
})
export class ProjectParamsService {
  settingsSet: any;
  apiUrl: any;
  constructor(private settings: SettingsService) {}

  getParams(project: string) {
    return new Promise((resolve, reject) => {
      this.apiUrl = this.settings.getSetting("apiUrl");
      axios
        .get(`${this.apiUrl}/params/${project}`)
        .then(d => {
          if (d.data.params) {
            resolve(d.data.params);
          } else {
            reject("MONGO: params not found found for " + project);
          }
        })
        .catch(err => {
          reject(err);
        });
    });
  }
}
