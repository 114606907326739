import { Component, OnInit } from "@angular/core";
import axios from "axios";
import { ActivatedRoute, Router } from "@angular/router";
import { SettingsService } from "../settings.service";
import * as $ from "jquery";
import "magnific-popup";
import { ProjectParamsService } from "../project-params.service";

@Component({
  selector: "app-user",
  templateUrl: "./user.component.html",
  styleUrls: ["./user.component.scss"]
})
export class UserComponent implements OnInit {
  apiUrl: any;
  lastName: any;
  user: any;
  project: any;
  publicUrl: any;
  bgImg: string;
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private settings: SettingsService,
    private projectParams: ProjectParamsService
  ) {
    this.apiUrl = this.settings.getSetting("apiUrl");
    this.publicUrl = this.settings.getSetting("publicUrl");
    this.lastName = this.route.snapshot.paramMap.get("lastname") || "not found";
    let project;
    project = this.route.snapshot.paramMap.get("project");
    if (!project) {
      project = this.settings.getSetting("defaultSite");
    }
    this.project = project;

    axios
      .get(`${this.apiUrl}/personByLastName/${this.lastName}`)
      .then(d => {
        this.user = d.data;
        if (!this.user) {
          console.log("User not found in project. Going to #team");
          this.router.navigateByUrl(`/#team`);
        }

        if (this.user.bgImg) {
          this.bgImg = this.user.bgImg;
        }

        if (this.user.picture) {
          let img = this.user.picture.filename;
          this.user["profileImg"] = `${this.publicUrl}/img/${img}`;
        }
        if (this.user.thesisImg) {
          this.user["thesisImgUrl"] = `${this.publicUrl}/img/${
            this.user.thesisImg
          }`;
        }
      })
      .catch(err => {
        console.log("Getting user failed", err);
      });
  }

  ngOnInit() {
    $("#menu").show();
  }

  ngAfterInit() {
    $(document).ready(() => {
      $(".thesisCover").magnificPopup({
        type: "image",
        delegate: "a"
      });
    });
  }

  goBack(e) {
    // e.preventDefault();
    // if (document.referrer.toString().includes("team")) {
    //   location.href = document.referrer.toString();
    // } else {
    if (!document.referrer.toString()) {
      location.href = "/#team";
    } else {
      location.href = "/team";
    }
    // }
  }
}
