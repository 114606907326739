// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.monit {
  display: none;
  z-index: 9999;
  position: absolute;
  right: 0;
  top: 100px;
  background-color: white;
  color: black;
  min-height: 10px;
  min-width: 100px;
}`, "",{"version":3,"sources":["webpack://./src/app/mainsite/mainsite.component.scss"],"names":[],"mappings":"AAAA;EACE,aAAA;EACA,aAAA;EACA,kBAAA;EACA,QAAA;EACA,UAAA;EACA,uBAAA;EACA,YAAA;EACA,gBAAA;EACA,gBAAA;AACF","sourcesContent":[".monit {\r\n  display: none;\r\n  z-index: 9999;\r\n  position: absolute;\r\n  right: 0;\r\n  top: 100px;\r\n  background-color: white;\r\n  color: black;\r\n  min-height: 10px;\r\n  min-width: 100px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
