import { Component, OnInit } from '@angular/core';
import axios from 'axios';
import { SettingsService } from '../settings.service';
import * as _ from 'lodash';
import { ActivatedRoute, Router } from '@angular/router';
import * as $ from 'jquery';
// import * as moment from 'moment';
import 'magnific-popup';
import { ProjectParamsService } from '../project-params.service';

@Component({
  selector: 'app-galleries',
  templateUrl: './galleries.component.html',
  styleUrls: ['./galleries.component.scss']
})
export class GalleriesComponent implements OnInit {
  apiUrl: any;
  galleries: any;
  slug: string;
  gallery: any;
  project: any;
  publicUrl: string;
  fColor12: any;
  fColor13: any;
  subsSet: boolean;
  parent: any;
  constructor(
    private settings: SettingsService,
    private route: ActivatedRoute,
    private projectParams: ProjectParamsService,
    private router: Router
  ) {
    let project;
    project = this.route.snapshot.paramMap.get('project');
    if (!project) {
      project = this.settings.getSetting('defaultSite');
    }
    this.project = project;
  }

  navPath(galleryKey: string, slug?: string) {
    if (typeof (slug) !== 'undefined') {
      return '/gallery/' + galleryKey + '?sub=' + slug;
    } else {
      return '/gallery/' + galleryKey;
    }
  }

  goBack(e: any) {
    e.preventDefault();

    if (this.parent) {
      location.href = '/gallery/' + this.parent
    } else {
      location.href = '/galleries'
    }

  }

  ngOnInit() {
    const context = this;
    $('#menu').show(() => {
      $('.parentC').magnificPopup({
        type: 'image',
        delegate: 'a',
        gallery: { enabled: true }
      });
    });
    this.apiUrl = this.settings.getSetting('apiUrl');
    this.publicUrl = this.settings.getSetting('publicUrl');
    context.slug = this.route.snapshot.paramMap.get('slug');
    this.route.queryParams.subscribe(r => {
      context.parent = r.sub || null;
    })

    const defaultProject = this.settings.getSetting('defaultSite');
    this.projectParams.getParams(defaultProject).then(s => {
      this.fColor12 = s['fColor12'];
      this.fColor13 = s['fColor13'];
    })

    axios
      .get(this.apiUrl + '/galleries')
      .then(d => {
        let galleries = d.data;
        galleries = _.sortBy(galleries, 'order');
        galleries = _.filter(galleries, g => {
          return g.published === true;
        });



        if (!context.slug) {
          _.map(galleries, gallery => {
            if (!gallery.thumb) {
              // gallery.thumb = gallery.images[0].secure_url;
            } else {
              gallery.thumb = this.publicUrl + '/img/' + gallery.thumb;
            }
            const pubDate = gallery.publishedDate;
            // gallery['pubDate'] = moment(pubDate).format('MMMM Do YYYY');
            return gallery;
          });
          this.galleries = galleries;
        } else {


          //Load sub-gallaries
          axios
            .get(this.apiUrl + '/galleries')
            .then(d => {
              let subgs = d.data;
              subgs = _.sortBy(subgs, 'order');
              subgs = _.filter(subgs, g => {
                return g.parent === context.slug;
              });

              _.map(subgs, gallery => {
                gallery.thumb = this.publicUrl + '/img/' + gallery.thumb;
                const pubDate = gallery.publishedDate;
                // gallery['pubDate'] = moment(pubDate).format('MMMM Do YYYY');
                return gallery;
              });

              this.galleries = subgs;
              this.subsSet = true;

            })

          let gallerySelection;


          gallerySelection = _.filter(galleries, { key: context.slug });



          const gallery: any = gallerySelection[0];
          if (!gallery.images) {
            gallery['images'] = [];
          }


          _.map(gallery.images, picture => {
            if (!picture.filename) {
              picture.url = picture.secure_url;
            } else {
              picture.url = this.publicUrl + '/img/' + picture.filename;
            }
            return picture;
          });

          axios
            .get(this.apiUrl + '/pictures')
            .then(lwPictures => {
              lwPictures.data = _.sortBy(lwPictures.data, 'order');
              _.map(lwPictures.data, p => {
                if (p['gallery'] === context.slug) {
                  const newPic = this.publicUrl + '/img/' + p.filename;
                  gallery.images.push({
                    url: newPic,
                    caption: p['caption']
                  });
                }
              });
            })
            .catch(err => console.error(err));



          this.gallery = gallery;
        }
      })
      .catch(err => {
        console.log(
          'Getting galleries failed',
          err,
          this.apiUrl + '/galleries'
        );
      });
  }
}
