import { Component, OnInit, Input } from "@angular/core";
import * as $ from "jquery";
import { Router } from "@angular/router";
import { TeamService } from "../team.service";

@Component({
  selector: "team-mobile-flex",
  templateUrl: "./team-mobile-flex.component.html",
  styleUrls: ["./team-mobile-flex.component.scss"]
})
export class TeamMobileFlexComponent implements OnInit {
  config: any;

  selectedIndex = 0;
  people: any;
  allPeople: any;

  constructor(private router: Router, private team: TeamService) { }

  ngOnInit() {
    this.team.getPeopleData(1).then(r => {
      this.people = r.allPeople;
    });
  }
  ngAfterInit() { }
  next() {
    let triggered = false;

    $(".fadeThis").fadeOut(() => {
      if (!triggered) {
        this.selectedIndex += 1;
        triggered = true;
      }

      if (this.selectedIndex >= this.people.length) {
        this.selectedIndex = 0;
      }
      $(".fadeThis").fadeIn();
    });
  }

  setIndex(offset: number) {
    this.selectedIndex += offset;
    if (this.selectedIndex < 0) {
      this.selectedIndex = this.people.length - 1;
    }
  }
  prev(m) {
    let triggered = false;

    $(".fadeThis").fadeOut(m => {
      if (!triggered) this.setIndex(-1);
      triggered = true;
      $(".fadeThis").fadeIn();
    });
  }

  goTo(url, event) {
    if (event) {
      if (
        event.target.className != "prev" &&
        event.target.className != "next"
      ) {
        this.router.navigateByUrl(url);
      }
    }
  }
}
