import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { SettingsService } from '../settings.service';
import * as $ from 'jquery'
import { ProjectParamsService } from '../project-params.service';


@Component({
  selector: 'app-teampage',
  templateUrl: './teampage.component.html',
  styleUrls: ['./teampage.component.scss']
})
export class TeampageComponent implements OnInit {
  project: string;
  teamPageBgImg: any;

  constructor(private route: ActivatedRoute, private settings: SettingsService,  private projectParams: ProjectParamsService) {
    let defaultProject = this.settings.getSetting("defaultSite");
    this.projectParams.getParams(defaultProject).then(settings => {
      this.teamPageBgImg = settings["teamPageBgImg"];
    });
  }

  ngOnInit() {
    let project: string;
    project = this.route.snapshot.paramMap.get("project");
    if (!project) {
      project = this.settings.getSetting("defaultSite");
    }
    this.project = project;
    $("#menu").show();
  }

}
