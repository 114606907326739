import { Component, OnInit, Input } from '@angular/core';
import { SettingsService } from '../settings.service';
import axios from 'axios';
import * as _ from 'lodash';
import { ProjectParamsService } from '../project-params.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: "shortcuts",
  templateUrl: './shortcuts.component.html',
  styleUrls: ['./shortcuts.component.scss']
})
export class ShortcutsComponent implements OnInit {
  shortcuts: any[];
  apiUrl: string;

  @Input() shortcutsTitle: string;
  bColor5: any;
  fColor5: any;
  constructor(private settings: SettingsService, private projectParams: ProjectParamsService) {
    this.apiUrl = this.settings.getSetting('apiUrl');
    const defaultProject = this.settings.getSetting('defaultSite');
    this.projectParams.getParams(defaultProject).then(s => {
      this.bColor5 = s['bColor5'];
      this.fColor5 = s['fColor5'];
    });

    axios
      .get(this.apiUrl + '/pages')
      .then(d => {
        this.shortcuts = d.data;
        this.shortcuts = _.map(this.shortcuts, o => {
          if (o.external) {
            o.slug = o.external;
          }
          if (o.showOverview) {
            return o;
          }
        });
        this.shortcuts = _.without(this.shortcuts, undefined);
        this.shortcuts = _.orderBy(this.shortcuts, 'sortOrder', 'asc');
      })
      .catch(err => {
        console.log('Getting collection failed', err);
      });
  }

  ngOnInit() { }
}
