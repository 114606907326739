import {
  Component,
  OnInit,
  NgModule,
  Output,
  EventEmitter,
  Input
} from '@angular/core';
import * as $ from 'jquery';
import axios from 'axios';
import { SettingsService } from '../settings.service';
import * as _ from 'lodash';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'twitter',
  templateUrl: './twitter.component.html',
  styleUrls: ['./twitter.component.scss'],
})
export class TwitterComponent implements OnInit {
  startId = 0;
  @Output() hover = new EventEmitter<boolean>();
  // tslint:disable-next-line: no-input-rename
  @Input('show') showOnInit: boolean;
  apiUrl: string;
  twitterScreenName: string;
  tweets = [];
  fullpage_api: any;
  isOnScroll: boolean;
  tweetsloaded: any;
  loading = true;
  scrolled: boolean;
  constructor(private settings: SettingsService) { }

  setTwitter = function (collapse: boolean) {
    if (!collapse) {
      $('.twitterdisplay').addClass('collapsed');
      $('.twitterdisplay').animate({ width: '0px' });
      $('.sidebar').animate({ height: '60px' });
    } else {
      $('.twitterdisplay').removeClass('collapsed');
      $('.twitterdisplay').animate({ width: '500px' });
      $('.sidebar').animate({ height: '500px' });
    }
  };

  toggleTwitter = function (collapse?) {
    if (collapse !== 0) {
      if ($('.twitterdisplay').hasClass('collapsed')) {
        this.setTwitter(true);
      } else if ($('.twitterdisplay').not('.collapsed')) {
        this.setTwitter(false);
      }
    } else {
      this.setTwitter(collapse);
    }
  };

  ngOnInit() {
    this.loadTweets();
  }

  isAtScrollBottom(e) {
    return e.target.scrollTop === e.target.scrollHeight - e.target.clientHeight;
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngAfterViewInit() {
    const context = this;
    $('.twitterdisplay').on('scroll', (e) => {
      context.scrolled = true;
      if (this.isAtScrollBottom(e)) {
        this.loadTweets();
      }
    });
  }
  loadTweets() {
    const context = this;
    if (!context.tweetsloaded || context.startId !== 0 && !context.loading) {
      context.loading = true;
      this.apiUrl = this.settings.getSetting('apiUrl');
      this.twitterScreenName = this.settings.getSetting('twitterScreenName');
      if (!this.twitterScreenName) {
        console.error('No twitter screen name configured');
      }
      axios
        .get(`${context.apiUrl}/twitter/${context.twitterScreenName}/3/${context.startId}`)
        .then(d => {
          context.tweets.push(...d.data);
          context.startId = d.data[d.data.length - 1].id;
          context.loading = false;
          // if (context.startId === 0) {
          $('body').append(
            '<script src="https://platform.twitter.com/widgets.js" charset="utf-8"></script>'
          );
          // }

          $('.tweet').show();
          context.tweetsloaded = true;
          if (context.showOnInit && screen.width > 768) {
            // context.setTwitter(true);
          } else {
            // context.setTwitter(false);
          }
        })
        .catch(err => {
          console.log('Error in fetching twitter data from server', err);
        });
    }
  }



  getPreview(event) { }
  getRef(fullPageRef) {
    this.fullpage_api = fullPageRef;
  }
  toggleHoverStatus(over: boolean) {
    this.isOnScroll = over;
    this.hover.emit(over);
  }
}
