// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  padding-top: 50px;
  margin-top: 20vh;
}

.card {
  margin-bottom: 15px;
  font-size: 1em;
}

a {
  color: black;
  text-decoration: none;
}

h3 {
  width: 100%;
  text-align: center;
  padding: 30px;
  font-size: 2em !important;
  margin-top: 2em;
}

h4 {
  text-align: center;
}

@media screen and (max-width: 768px) {
  .styledH2 {
    padding: 15px;
  }
  h3 {
    margin-top: 100px;
  }
}
.card-block {
  height: 170px;
  padding: 10px;
}
.card-block .card-text {
  font-size: 0.9em;
}`, "",{"version":3,"sources":["webpack://./src/app/shortcuts/shortcuts.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,gBAAA;AACF;;AAEA;EACE,mBAAA;EACA,cAAA;AACF;;AAEA;EACE,YAAA;EACA,qBAAA;AACF;;AAEA;EACE,WAAA;EACA,kBAAA;EACA,aAAA;EACA,yBAAA;EACA,eAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE;IACE,aAAA;EACF;EAEA;IACE,iBAAA;EAAF;AACF;AAGA;EACE,aAAA;EACA,aAAA;AADF;AAGE;EACE,gBAAA;AADJ","sourcesContent":[".container {\r\n  padding-top: 50px;\r\n  margin-top: 20vh;\r\n}\r\n\r\n.card {\r\n  margin-bottom: 15px;\r\n  font-size: 1em;\r\n}\r\n\r\na {\r\n  color: black;\r\n  text-decoration: none;\r\n}\r\n\r\nh3 {\r\n  width: 100%;\r\n  text-align: center;\r\n  padding: 30px;\r\n  font-size: 2em !important;\r\n  margin-top: 2em;\r\n}\r\n\r\nh4 {\r\n  text-align: center;\r\n}\r\n\r\n@media screen and (max-width: 768px) {\r\n  .styledH2 {\r\n    padding: 15px;\r\n  }\r\n\r\n  h3 {\r\n    margin-top: 100px;\r\n  }\r\n}\r\n\r\n.card-block {\r\n  height: 170px;\r\n  padding: 10px;\r\n\r\n  .card-text {\r\n    font-size: 0.9em;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
