import { Injectable } from "@angular/core";
import { SettingsService } from "./settings.service";
import axios from "axios";
import { ActivatedRouteSnapshot, RouterStateSnapshot, Route, Router } from "@angular/router";
import { Observable } from "rxjs";
import { PageService } from "./page.service";

@Injectable({
  providedIn: "root"
})
export class PageresolverService  {
  constructor(
    private pageService: PageService,
    private router: Router,
    private settings: SettingsService
  ) { }

  resolve(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Observable<object> | boolean {
    return Observable.create(observer => {
      const project =
        route.paramMap.get("project") ||
        this.settings.getSetting("defaultSite");
      const page = route.paramMap.get("page");
      let pageO;
      if (page) {
        this.pageService
          .getPage(project, page)
          .then(o => {
            pageO = o.page;
            observer.next(pageO);
            observer.complete();
          })
          .catch(o => {
            if (o.pageFound === false) {
              this.pageService.getPage(project).then(o => {
                if (o.page) {
                  pageO = o.page;
                  observer.next(pageO);
                  observer.complete();
                } else {
                  pageO = false;
                  observer.next(false);
                  observer.complete();
                }
              });
            }
          });
      } else {
        this.pageService
          .getPage(project)
          .then(o => {
            if (o.page) {
              pageO = o.page;
              observer.next(pageO);
              observer.complete();
            } else {
              pageO = false;
              observer.next(false);
              observer.complete();
            }
          })
          .catch(err => {
            console.log("Project not found", err);
            this.router.navigateByUrl("/#projects");
            observer.next(false);
            observer.complete();
          });
      }
    });
  }

  // const page = this.route.paramMap.get("page");
}
