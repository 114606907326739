import { Component, enableProdMode } from "@angular/core";
import * as $ from "jquery";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-root",
  templateUrl: "./app.component.html",
  styleUrls: ["./app.component.scss"]
})
export class AppComponent {
  constructor() {
  }

  ngOnInit() {}
}
