import { Component, OnInit, Input, ViewChild, ElementRef } from "@angular/core";
import { SettingsService } from "../settings.service";
import { TeamService } from "../team.service";

@Component({
  selector: "team",
  templateUrl: "./team.component.html",
  styleUrls: ["./team.component.scss"]
})
export class TeamComponent implements OnInit {
  people: any;
  allPeople: any;

  cols = 6;
  constructor(private team: TeamService) {}

  ngOnInit() {
    this.team.getPeopleData(this.cols).then(r => {
      this.people = r.people;
      this.allPeople = r.allPeople;
    });
  }
}
