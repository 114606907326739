import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppComponent } from './app.component';
import { CoverComponent } from './cover/cover.component';
import { MenuComponent } from './menu/menu.component';
import { SettingsService } from './settings.service';
import { HttpClientModule } from '@angular/common/http';
import { ShortcutsComponent } from './shortcuts/shortcuts.component';
import { PostwidgetComponent } from './postwidget/postwidget.component';
import { TwitterComponent } from './twitter/twitter.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgInviewModule } from 'angular-inport';
import { ProjectsSectionComponent } from './projects-section/projects-section.component';
import { RouterModule, Routes } from '@angular/router';
import { MainsiteComponent } from './mainsite/mainsite.component';
import { ProjectsiteComponent } from './projectsite/projectsite.component';
import { SectionStylingDirective } from './section-styling.directive';
import { TeamComponent } from './team/team.component';
import { PublicationswidgetComponent } from './publicationswidget/publicationswidget.component';
import { TeamMobileFlexComponent } from './team-mobile-flex/team-mobile-flex.component';
import { PublicationsComponent } from './publications/publications.component';
import { PageComponent } from './page/page.component';
import { UserComponent } from './user/user.component';
import { GalleriesComponent } from './galleries/galleries.component';
import { PageresolverService } from './pageresolver.service';
import * as environment from '../../src/environments/environment';
import { PostsComponent } from './posts/posts.component';
import { TeampageComponent } from './teampage/teampage.component';
import { TeamSectionComponent } from './team-section/team-section.component';
import { PublicationComponent } from './publication/publication.component';
import { PdfComponent } from './pdf/pdf.component';
import { UserStylingDirective } from './user-styling.directive';
import { SafeHtmlPipe } from './safehtml.pipe';
import { PageSylingDirective } from './page-syling.directive';
import { SafePipe } from './save.pipe'
import { AngularFullpageModule } from '@fullpage/angular-fullpage';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

const appRoutes: Routes = [
  { path: '', component: MainsiteComponent },
  // { path: "publications", component: PublicationsComponent },
  { path: 'show/:file', component: PublicationComponent },
  { path: 'galleries', component: GalleriesComponent },
  { path: 'posts', component: PostsComponent },
  { path: 'publications', component: PublicationsComponent },
  { path: 'team', component: TeampageComponent },
  { path: 'gallery/:slug', component: GalleriesComponent },
  { path: 'team/:lastname', component: UserComponent },
  {
    path: ':page',
    component: PageComponent,
    resolve: { page: PageresolverService }
  },
  {
    path: 'project/:project',
    component: PageComponent,
    resolve: { page: PageresolverService }
  },
  {
    path: 'project/:project/:page',
    component: PageComponent,
    resolve: { page: PageresolverService }
  },
  { path: ':project/home', component: ProjectsiteComponent }
];

@NgModule({
  declarations: [
    AppComponent,
    CoverComponent,
    MenuComponent,
    ShortcutsComponent,
    PostwidgetComponent,
    TwitterComponent,
    ProjectsSectionComponent,
    MainsiteComponent,
    ProjectsiteComponent,
    SectionStylingDirective,
    TeamComponent,
    PublicationswidgetComponent,
    TeamMobileFlexComponent,
    PageComponent,
    UserComponent,
    GalleriesComponent,
    PostsComponent,
    TeampageComponent,
    TeamSectionComponent,
    PublicationComponent,
    PublicationsComponent,
    PdfComponent,
    UserStylingDirective,
    SafeHtmlPipe,
    PageSylingDirective,
    SafePipe
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    BrowserAnimationsModule,
    AngularFullpageModule,
    RouterModule.forRoot(appRoutes, {}),
    NgbModule,
  ],
  providers: [SettingsService, PageresolverService],
  bootstrap: [AppComponent]
})
export class AppModule { }
