import { Component, OnInit, Input } from "@angular/core";
import { SettingsService } from "../settings.service";
import axios from "axios";
import * as _ from "lodash";
import { $ } from "jquery";

@Component({
  selector: "menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"]
})
export class MenuComponent implements OnInit {
  @Input("project") project: string;
  pages: any[];
  apiUrl: string;
  isCollapsed: boolean = true;
  activeSlug: any;
  currentPage: any;
  constructor(private settings: SettingsService) {}
  ngOnInit() {
    const path = window.location.href;
    let page;
    let a = path.split("#");
    if (a[1]) {
      page = a[1];
    } else {
      a = path.split("/");
      page = a[a.length - 1];
    }

    this.currentPage = page;
    this.apiUrl = this.settings.getSetting("apiUrl");
    let url: string;
    let projectPrefix: string = "";
    if (!this.project) {
      url = this.apiUrl + "/pages";
    } else {
      url = `${this.apiUrl}/pages/project/${this.project}`;
      if (this.project !== this.settings.getSetting("defaultSite")) {
        projectPrefix = `/project/${this.project}`;
      }
    }
    axios
      .get(url)
      .then(d => {
        this.pages = d.data;
        this.pages = _.map(this.pages, o => {
          if (o.showMenu) {
            if (o.external) {
              o.link = o.external;
            } else {
              if (o.showBelow) {
                o.link = projectPrefix + "#" + o.slug;
              } else {
                o.link = projectPrefix + "/" + o.slug;
              }
            }

            if (o.slug === page) {
              o.active = "active";
            }
            return o;
          }
        });
        this.pages = _.without(this.pages, undefined);
        this.pages = _.orderBy(this.pages, "sortOrder", "asc");
        if (this.project != this.settings.getSetting("defaultSite")) {
          this.pages.unshift({ link: "/", title: "Home", mobile: false });
        } else {
          this.pages.unshift({ link: "/", title: "Home", mobile: true });
        }
      })
      .catch(err => {
        console.log("Getting collection failed", err);
      });
  }
  hide() {
    this.isCollapsed = true;
  }
}
