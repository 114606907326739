import { Injectable } from "@angular/core";
import { SettingsService } from "./settings.service";
import axios from "axios";

@Injectable({
  providedIn: "root"
})
export class PageService {
  private apiUrl: string;
  publicUrl: string;
  constructor(private settings: SettingsService) { }
  getPage(project: string, page?: string) {
    return new Promise<any>((resolve, reject) => {
      this.apiUrl = this.settings.getSetting("apiUrl");
      this.publicUrl = this.settings.getSetting("publicUrl");
      let url: string;
      if (page) {
        url = `${this.apiUrl}/page/${project}/${page}`;
      } else {
        url = `${this.apiUrl}/page/${project}/home`;
      }

      let pageObject: any;
      axios
        .get(url)
        .then(d => {
          pageObject = d.data[0];
          if (!pageObject) {
            console.log("Page not found in project.");
            reject({ pageFound: false });
          } else {
            if (pageObject.backgroundPicture) {
              let img = pageObject.backgroundPicture.filename;
              pageObject["bgImg"] = `${this.publicUrl}/img/${img}`;
            } else {
              pageObject["bgImg"] = null;
            }

            if (pageObject.picture && pageObject.picture.filename) {
              let img = pageObject.picture.filename;
              pageObject["picture"] = `${this.publicUrl}/img/${img}`;
            } else {
              pageObject["picture"] = null;
            }

            if (pageObject.extraPicture && pageObject.extraPicture.filename) {
              let img = pageObject.extraPicture.filename;
              pageObject["extraPicture"] = `${this.publicUrl}/img/${img}`;
            } else {
              pageObject["extraPicture"] = null;
            }

            resolve({ page: pageObject, pageFound: true });
          }
        })
        .catch(err => {
          console.log("Getting page in service failed", err);
          reject({ pageFound: undefined, err: err });
        });
    });
  }
}
