import { Component, OnInit, Output } from '@angular/core';
import * as $ from 'jquery';
import { ActivatedRoute, Router, NavigationEnd } from '@angular/router';
import { filter } from 'rxjs/operators';
import { ProjectParamsService } from '../project-params.service';
import 'magnific-popup';
import { SettingsService } from '../settings.service';

@Component({
  selector: 'app-mainsite',
  templateUrl: './mainsite.component.html',
  styleUrls: ['./mainsite.component.scss']
})
export class MainsiteComponent implements OnInit {
  title = 'Engel Lab';

  config: any;
  fullpage_api: any;

  newsBgImg: any;

  setTwitter: (collapse: boolean) => void;
  fpAllowScrolling = true;
  hasProjects: boolean;
  loadingDone: boolean;
  teamImg: string;

  // SHOW OR HIDE MENU IN FIRST SECION?
  showMenuInit = false;
  shortcutsTitle: any;
  labSectionBg: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private projectParams: ProjectParamsService,
    private settings: SettingsService
  ) {
    const defaultProject = this.settings.getSetting('defaultSite');
    this.projectParams.getParams(defaultProject).then(s => {
      this.teamImg = s['teamImg'];
      this.shortcutsTitle = s['shortcutsTitle'];
    });

    this.setTwitter = (collapse: boolean) => {
      if (!collapse) {
        $('.twitterdisplay').addClass('collapsed');
        $('.twitterdisplay').animate({ width: '0px' });
        $('.sidebar').animate({ height: '60px' });
      } else {
        $('.twitterdisplay').removeClass('collapsed');
        $('.twitterdisplay').animate({ width: '500px' });
        $('.sidebar').animate({ height: '500px' });
      }
    };

    this.config = {
      // fullpage options
      licenseKey: '0D1D43DC-B08C4DE9-83D4309B-4950CF7B',
      anchors: ['', 'news', 'lab', 'projects', 'team', 'publications'],
      menu: '#menu',
      navigation: true,
      slidesNavigation: true,
      normalScrollElements: '.scrollPost',
      scrollOverflow: false,
      animateAnchor: false,
      responsiveWidth: 993,
      bigSectionsDestination: 'top',

      // fullpage callbacks
      afterLoad: (origin, destination, direction) => {
        const nextIndex = destination.index;

        if (nextIndex === 1 && screen.width > 768) {
          this.setTwitter(true);
        } else {
          this.setTwitter(false);
        }

        if (nextIndex >= 1 || this.showMenuInit) {
          $('#menu').fadeIn(800);
        } else {
          $('#menu').fadeOut(500);
        }

        if (nextIndex === 4 || nextIndex === 5) {
          $('.downer').fadeOut();
        } else {
          $('.downer').fadeIn();
        }
        $('a').attr('active', false);
        if (destination.anchor) {
          const selector = `#${destination.anchor}`;
          $(selector).attr('active', true);
        }
        this.loadingDone = true;

        if (this.canbeMagicMouse()) {
          // console.log('can be Magic Mouse')
          // this.fullpage_api.setResponsive(true);
          // this.fullpage_api.reBuild();
        }
      },
      onLeave: (index, next, direction) => {
        return this.fpAllowScrolling;
      }
    };
  }
  projectsLoaded(has: boolean) {
    this.hasProjects = has;
  }
  onHover(hover: boolean) {
    if (hover) {
      this.fpAllowScrolling = false;
    } else {
      this.fpAllowScrolling = true;
    }
  }
  getRef(fullPageRef) {
    this.fullpage_api = fullPageRef;
  }

  canbeMagicMouse() {
    // console.log({
    //   'screen width': screen.width,
    //   'screen height': screen.height,
    //   UA: navigator.userAgent,
    //   'Is mac': navigator.userAgent.search('Macintosh') > 0,
    //   'Is windows': navigator.userAgent.search('Windows') > 0
    // });

    let mm = false;
    // var win = (navigator.userAgent.search("Windows")>0);
    const mac = navigator.userAgent.search('Macintosh') > 0;
    if (screen.width === 2560 && screen.height === 1440 && mac) {
      mm = true;
    }
    return true;
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngAfterViewInit() {
    $('.downer').click(() => {
      this.fullpage_api.moveSectionDown();
    });

    $('.logospace').click(() => {
      this.fullpage_api.moveSectionDown();
    });

    $(document).ready(() => {
      $('.coverImgContainer').magnificPopup({
        type: 'image',
        delegate: 'a',
        gallery: { enabled: true }
      });
    });
  }
  ngOnInit() { }
}
