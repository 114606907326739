import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { DeviceDetectorService } from "ngx-device-detector";
import { SettingsService } from "../settings.service";
import * as $ from "jquery";
import { ProjectParamsService } from "../project-params.service";

@Component({
  selector: "cover",
  templateUrl: "./cover.component.html",
  styleUrls: ["./cover.component.scss"]
})
export class CoverComponent implements OnInit {
  @ViewChild("myVideo") myVideo: ElementRef;
  deviceInfo: any;
  videoUrlMp4: string;
  videoUrlWebM: string;
  videoUrlAvi: string;
  videoOffset: number = 0;

  coverImg: any;
  bColor1: any;
  fColor1: any;
  bColor2: any;
  fColor2: any;

  constructor(
    private deviceService: DeviceDetectorService,
    private settings: SettingsService,
    private projectParams: ProjectParamsService
  ) {
    let defaultProject = this.settings.getSetting("defaultSite");
    this.projectParams.getParams(defaultProject).then(s => {
      this.coverImg = s["coverImg"];
      this.bColor1 = s['bColor10']
      this.fColor1 = s['fColor10']
      this.bColor2 = s['bColor11']
      this.fColor2 = s['fColor11']
    });
  }

  ngOnInit() {
    this.deviceInfo = JSON.stringify(this.deviceService.getDeviceInfo());
    if (this.deviceService.isDesktop()) {
      this.setVideos();
    }
  }

  setVideos() {
    const publicUrl = this.settings.getSetting("publicUrl");
    const offset = this.videoOffset;
    this.videoUrlMp4 = `${publicUrl}/video/${offset}/getToday.mp4.${offset}`;
    this.videoUrlWebM = `${publicUrl}/video/${offset}/getToday.webm.${offset}`;
    this.videoUrlAvi = `${publicUrl}/video/${offset}/getToday.avi.${offset}`;

    //!commented out for no video
    //this.myVideo.nativeElement.load();
  }

  nextVideo() {
    this.videoOffset += 1;
    this.setVideos();
  }
  previousVideo() {
    this.videoOffset -= 1;
    this.setVideos();
  }

  fullVideo(full: boolean) {
    if (full) {
      $("#myVideo").css({ "z-index": "99" });
    } else {
      $("#myVideo").css({ "z-index": "1" });
    }
  }
}
