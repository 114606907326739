import { Component, OnInit } from "@angular/core";
import { ProjectParamsService } from "../project-params.service";
import { SettingsService } from "../settings.service";
import { ActivatedRoute } from "@angular/router";
import axios from "axios";
import * as $ from "jquery";
import * as _ from "lodash";

@Component({
  selector: "app-posts",
  templateUrl: "./posts.component.html",
  styleUrls: ["./posts.component.scss"]
})
export class PostsComponent implements OnInit {
  postsPageBgImg: any;
  project: any;
  apiUrl: string;
  posts: any;
  loadingDone: boolean;
  constructor(
    private projectParams: ProjectParamsService,
    private settingsService: SettingsService,
    private route: ActivatedRoute,
    private settings: SettingsService
  ) {
    let project;
    project = this.route.snapshot.paramMap.get("project");
    if (!project) {
      project = this.settings.getSetting("defaultSite");
    }
    this.project = project;
    let defaultProject = this.settingsService.getSetting("defaultSite");
    this.projectParams
      .getParams(defaultProject)
      .then(settings => {
        this.postsPageBgImg = settings["postsPageBgImg"];
      })
      .catch(e => {
        console.log('posts error', e);
      });
  }

  ngOnInit() { }

  ngAfterViewInit() {
    $("#menu").show();

    this.loadingDone = true;
    $(".twitterTogglerImg").click();
    this.apiUrl = this.settings.getSetting("apiUrl");
    let url: string;
    let projectPrefix: string = "";
    if (!this.project) {
      url = this.apiUrl + "/posts";
    } else {
      //Update below for project specific posts, also update server
      // url = `${this.apiUrl}/pages/project/${this.project}`;
      // if (this.project !== this.settings.getSetting("defaultSite")) {
      //   projectPrefix = `/project/${this.project}`;
      // }
      url = this.apiUrl + "/posts";
    }
    axios
      .get(url)
      .then(d => {
        this.posts = d.data;
        this.posts = _.orderBy(this.posts, "publishedDate", "desc");
      })
      .catch(err => {
        console.log("Getting collection failed", err);
      });
  }
}
