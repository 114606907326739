import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ProjectParamsService } from "../project-params.service";
import * as $ from "jquery";

@Component({
  selector: "app-projectsite",
  templateUrl: "./projectsite.component.html",
  styleUrls: ["./projectsite.component.scss"]
})
export class ProjectsiteComponent implements OnInit {
  project: string;
  fullpage_api: any;
  config: {
    // fullpage options
    licenseKey: string;
    anchors: string[];
    menu: string;
    navigation: boolean;
    slidesNavigation: boolean;
    // fullpage callbacks
    afterResize: () => void;
    afterLoad: (origin: any, destination: any, direction: any) => void;
    onLeave: (index: any, next: any, direction: any) => void;
  };
  coverImg: any;
  params: any;
  tmp_coverImg: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private projectParams: ProjectParamsService
  ) {
    this.config = {
      // fullpage options
      licenseKey: "0D1D43DC-B08C4DE9-83D4309B-4950CF7B",
      anchors: [],
      menu: "#menu",
      navigation: true,
      slidesNavigation: true,
      // fullpage callbacks
      afterResize: () => {},
      afterLoad: (origin, destination, direction) => {},
      onLeave: function(index, next, direction) {}
    };
    this.project = this.route.snapshot.paramMap.get("project") || "not found";
    this.projectParams
      .getParams(this.project)
      .then(settings => {
        this.project = settings["title"];
        this.tmp_coverImg = settings["coverImg"];
        this.coverImg = this.tmp_coverImg;
      })
      .catch(err => {
        console.log("Params not found:", err);
        router.navigateByUrl("/");
      });
  }

  ngOnInit() {
    $("#menu").show();
  }
  getRef(fullPageRef) {
    this.fullpage_api = fullPageRef;
  }
}
