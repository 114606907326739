// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-fluid {
  width: 100%;
  left: 0;
  top: 0;
  bottom: 0;
  right: 0;
  background-size: cover;
}

.teamPageContainer {
  min-height: 100%;
}

.titleContainer {
  position: relative;
  background: white;
  padding-top: 20px;
  padding-bottom: 5px;
  margin: 0;
  top: 80px;
  text-align: center;
  width: 100%;
}

.teamMobileFlexContainer {
  width: 100%;
  min-height: 100%;
}`, "",{"version":3,"sources":["webpack://./src/app/teampage/teampage.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,OAAA;EACA,MAAA;EACA,SAAA;EACA,QAAA;EACA,sBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,kBAAA;EACA,iBAAA;EACA,iBAAA;EACA,mBAAA;EACA,SAAA;EACA,SAAA;EACA,kBAAA;EACA,WAAA;AACF;;AAEA;EACE,WAAA;EACA,gBAAA;AACF","sourcesContent":[".container-fluid {\r\n  width: 100%;\r\n  left: 0;\r\n  top: 0;\r\n  bottom: 0;\r\n  right: 0;\r\n  background-size: cover;\r\n}\r\n\r\n.teamPageContainer {\r\n  min-height: 100%;\r\n}\r\n\r\n.titleContainer {\r\n  position: relative;\r\n  background: white;\r\n  padding-top: 20px;\r\n  padding-bottom: 5px;\r\n  margin: 0;\r\n  top: 80px;\r\n  text-align: center;\r\n  width: 100%;\r\n}\r\n\r\n.teamMobileFlexContainer {\r\n  width: 100%;\r\n  min-height: 100%;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
