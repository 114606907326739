// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container-fluid {
  min-height: 100%;
  background-size: cover;
}

.container {
  padding-top: 20vh;
}

.galleryTitle {
  font-size: 1.2em;
}

img {
  height: 200px;
  padding: 10px;
}

.galleryCard {
  background-color: rgba(255, 255, 255, 0.9);
  border-radius: 0.125rem;
  text-align: center;
  min-height: 250px;
  margin-bottom: 15px;
  overflow-x: hidden;
}

.galleryCard:hover {
  background-color: #d4d4e4;
}

.btn {
  background-color: rgba(255, 255, 255, 0.4);
}

.goBack {
  display: block;
  padding-bottom: 15px;
}`, "",{"version":3,"sources":["webpack://./src/app/galleries/galleries.component.scss"],"names":[],"mappings":"AAAA;EACE,gBAAA;EACA,sBAAA;AACF;;AAEA;EACE,iBAAA;AACF;;AAEA;EACE,gBAAA;AACF;;AAEA;EACE,aAAA;EACA,aAAA;AACF;;AAEA;EACE,0CAAA;EACA,uBAAA;EACA,kBAAA;EACA,iBAAA;EACA,mBAAA;EACA,kBAAA;AACF;;AAEA;EACE,yBAAA;AACF;;AAEA;EACE,0CAAA;AACF;;AAEA;EACE,cAAA;EACA,oBAAA;AACF","sourcesContent":[".container-fluid {\r\n  min-height: 100%;\r\n  background-size: cover;\r\n}\r\n\r\n.container {\r\n  padding-top: 20vh;\r\n}\r\n\r\n.galleryTitle {\r\n  font-size: 1.2em;\r\n}\r\n\r\nimg {\r\n  height: 200px;\r\n  padding: 10px;\r\n}\r\n\r\n.galleryCard {\r\n  background-color: rgba(255, 255, 255, 0.9);\r\n  border-radius: 0.125rem;\r\n  text-align: center;\r\n  min-height: 250px;\r\n  margin-bottom: 15px;\r\n  overflow-x: hidden;\r\n}\r\n\r\n.galleryCard:hover {\r\n  background-color: #d4d4e4;\r\n}\r\n\r\n.btn {\r\n  background-color: rgba(255, 255, 255, 0.4);\r\n}\r\n\r\n.goBack {\r\n  display: block;\r\n  padding-bottom: 15px;\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
