import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { SettingsService } from "../settings.service";
import axios from "axios";
import * as $ from "jquery";


@Component({
  selector: "page",
  templateUrl: "./page.component.html",
  styleUrls: ["./page.component.scss"]
})
export class PageComponent implements OnInit {
  project: any;
  page: any;
  apiUrl: string;
  bgImg: any;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private settings: SettingsService
  ) {
    route.data.subscribe(d => {
      this.page = d.page;
      const imgUrl = d.page.bgImg.split('/');
      this.bgImg = imgUrl[imgUrl.length - 1]
    });
    let project: string;
    project = this.route.snapshot.paramMap.get("project");
    if (!project) {
      project = this.settings.getSetting("defaultSite");
    }
    this.project = project;
  }

  ngOnInit() {
    $("#menu").show();
  }
}
