import {
  Component,
  OnInit,
  Directive,
  ElementRef,
  Input,
  enableProdMode
} from '@angular/core';
import { SettingsService } from '../settings.service';
import axios from 'axios';
import * as _ from 'lodash';
import * as $ from 'jquery';
// import * as moment from 'moment';
import 'magnific-popup';
import { ProjectParamsService } from '../project-params.service';

@Component({
  selector: "postwidget",
  templateUrl: './postwidget.component.html',
  styleUrls: ['./postwidget.component.scss']
})
export class PostwidgetComponent implements OnInit {
  posts: any[];
  apiUrl: string;
  msg: string;
  publicUrl: string;
  bColor3: any;
  fColor3: any;
  bColor4: any;
  fColor4: any;
  constructor(private settings: SettingsService, private projectParams: ProjectParamsService) {
    const defaultProject = this.settings.getSetting('defaultSite');
    this.projectParams.getParams(defaultProject).then(s => {
      this.bColor3 = s['bColor3'];
      this.fColor3 = s['fColor3'];
      this.bColor4 = s['bColor4'];
      this.fColor4 = s['fColor4'];
    });
  }
  @Input() length: number;

  ngOnInit() {
    this.apiUrl = this.settings.getSetting('apiUrl');
    this.publicUrl = this.settings.getSetting('publicUrl');

    axios
      .get(this.apiUrl + '/posts')
      .then(d => {
        this.posts = d.data;
        this.posts = _.sortBy(this.posts, ['publishedDate', 'desc']);
        this.posts = this.posts.reverse();
        if (this.length) {
          this.posts = this.posts.slice(0, this.length);
        }

        _.map(this.posts, post => {
          let pubDate = post.publishedDate;

          if (post.picture) {
            let img = post.picture.filename;
            if (post.picture.filename) {
              post['imgUrl'] = `${this.publicUrl}/img/${img}`;
            } else {
              post['imgUrl'] = undefined;
            }
            setTimeout(() => {
              this.enableMP();
            }, 2000);
          }
          // pubDate = moment(pubDate).format('MMMM Do YYYY');
          post['date'] = pubDate;

          return post;
        });
      })
      .catch(err => {
        console.log('Getting collection failed', err);
      });
  }

  enableMP() {
    $('.parentC').magnificPopup({
      type: 'image',
      delegate: 'a.pop'
    });
  }

  h3Click(e, isChild?: boolean) {
    let target = e;
    if (!isChild) {
      target = e.target;
    }

    var post = $(target).parent();
    $(post)
      .siblings()
      .find('.content')
      .hide();

    $(post)
      .find('.content')
      .slideToggle('slow', function () {
        if ($(this).is(':visible')) {
          //            ga('send', 'pageview', "News: " + $(this).parent().find("h3").text());
        }
      });

    $('.parentC').magnificPopup({
      type: 'image',
      delegate: 'a.pop'
    });
  }
  h3ChildClick(e) {
    this.h3Click($(e.target).parent(), true);
  }

  goToPosts() {
    location.href = '/posts';
  }
}
