import { Component, OnInit } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-publication",
  templateUrl: "./publication.component.html",
  styleUrls: ["./publication.component.scss"]
})
export class PublicationComponent implements OnInit {
  file: string;

  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    this.file = this.route.snapshot.paramMap.get("file");
    window.location.href =
      "http://docs.google.com/viewer?url=https://engelbeelab.com/rest/pdf/" +
      this.file +
      "&embed=true";
  }
}
