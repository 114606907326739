import { Directive, ElementRef, Input } from "@angular/core";
import { SettingsService } from "./settings.service";
import { ProjectParamsService } from "./project-params.service";

@Directive({
  selector: "[SectionStyling]"
})
export class SectionStylingDirective {
  // tslint:disable-next-line: no-input-rename
  @Input('backgroundImg') backgroundImg: string;


  element: any;
  constructor(
    el: ElementRef,
    private settings: SettingsService,
    private projectParams: ProjectParamsService
  ) {
    this.element = el.nativeElement;
  }

  // tslint:disable-next-line: use-life-cycle-interface
  ngOnInit() {
    const site = this.settings.getSetting('defaultSite');
    this.projectParams.getParams(site).then(settings => {
      const publicUrl = this.settings.getSetting('publicUrl');
      const fileName = settings[this.backgroundImg];
      const url = `${publicUrl}/img/${fileName}`;
      this.element.style.backgroundImage = `url('${url}')`;
    }).catch(e => {
      console.error(e)
    });
  }
}
