// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  display: inline-block;
  height: 100%;
  overflow-y: scroll;
  background-color: rgba(0, 0, 0, 0.8);
  margin: 0 auto;
}

.tweet {
  visibility: hidden;
}

h3 {
  color: white;
  width: 100%;
  text-align: center;
}

img {
  display: inline-block;
}

.twitterTogglerImg {
  display: inline-block;
  width: 50px;
  height: 50px;
  margin: auto;
  position: absolute;
  top: 50px;
  cursor: pointer;
}

@media screen and (max-width: 768px) {
  .twitterTogglerImg {
    top: 80vh;
  }
}
.twitterdisplay {
  overflow-y: scroll;
  width: 0;
  padding-right: 35px;
}

.collapsed {
  padding-left: 0 !important;
  padding-right: 0 !important;
}

.buttonRow {
  margin-top: 100px;
  margin-bottom: 100px;
}
.buttonRow .btn {
  background-color: #097272;
  color: #ffffff;
}`, "",{"version":3,"sources":["webpack://./src/app/twitter/twitter.component.scss"],"names":[],"mappings":"AAAA;EACE,qBAAA;EACA,YAAA;EACA,kBAAA;EACA,oCAAA;EACA,cAAA;AACF;;AAEA;EACE,kBAAA;AACF;;AAEA;EACE,YAAA;EACA,WAAA;EACA,kBAAA;AACF;;AAEA;EACE,qBAAA;AACF;;AAEA;EACE,qBAAA;EACA,WAAA;EACA,YAAA;EACA,YAAA;EACA,kBAAA;EACA,SAAA;EACA,eAAA;AACF;;AAEA;EACE;IACE,SAAA;EACF;AACF;AAEA;EACE,kBAAA;EACA,QAAA;EACA,mBAAA;AAAF;;AAGA;EACE,0BAAA;EACA,2BAAA;AAAF;;AAGA;EACE,iBAAA;EACA,oBAAA;AAAF;AACE;EACE,yBAAA;EACA,cAAA;AACJ","sourcesContent":[".container {\r\n  display: inline-block;\r\n  height: 100%;\r\n  overflow-y: scroll;\r\n  background-color: rgba($color: #000000, $alpha: 0.8);\r\n  margin: 0 auto;\r\n}\r\n\r\n.tweet {\r\n  visibility: hidden;\r\n}\r\n\r\nh3 {\r\n  color: white;\r\n  width: 100%;\r\n  text-align: center;\r\n}\r\n\r\nimg {\r\n  display: inline-block;\r\n}\r\n\r\n.twitterTogglerImg {\r\n  display: inline-block;\r\n  width: 50px;\r\n  height: 50px;\r\n  margin: auto;\r\n  position: absolute;\r\n  top: 50px;\r\n  cursor: pointer;\r\n}\r\n\r\n@media screen and (max-width: 768px) {\r\n  .twitterTogglerImg {\r\n    top: 80vh;\r\n  }\r\n}\r\n\r\n.twitterdisplay {\r\n  overflow-y: scroll;\r\n  width: 0;\r\n  padding-right: 35px;\r\n}\r\n\r\n.collapsed {\r\n  padding-left: 0 !important;\r\n  padding-right: 0 !important;\r\n}\r\n\r\n.buttonRow {\r\n  margin-top: 100px;\r\n  margin-bottom: 100px;\r\n  .btn {\r\n    background-color: #097272;\r\n    color: #ffffff;\r\n  }\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
