import { Component, OnInit } from "@angular/core";
import * as $ from "jquery";
import { ProjectParamsService } from "../project-params.service";
import { SettingsService } from "../settings.service";

@Component({
  selector: "app-publications",
  templateUrl: "./publications.component.html",
  styleUrls: ["./publications.component.scss"]
})
export class PublicationsComponent implements OnInit {
  publicationsPageBgImg: any;
  constructor(
    private projectParams: ProjectParamsService,
    private settingsService: SettingsService
  ) {
    let defaultProject = this.settingsService.getSetting("defaultSite");
    this.projectParams.getParams(defaultProject).then(settings => {
      this.publicationsPageBgImg = settings["publicationsPageBgImg"];
    });
  }

  ngOnInit() {
    $("#menu").show();
  }
}
