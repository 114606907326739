// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.container {
  margin-left: auto;
  margin-right: auto;
  padding: 90px;
  padding-top: 18vh;
}

.card {
  cursor: pointer;
  position: relative;
  width: 100%;
}

.imgContainer {
  max-height: 50vh;
  overflow: hidden;
}
.imgContainer img {
  padding: 50px;
}

.styledH2 {
  margin-bottom: 20px;
}

@media screen and (max-width: 768px) {
  .container {
    padding: 0px;
  }
  .container-fluid {
    padding: 0;
  }
  img {
    height: auto;
  }
}`, "",{"version":3,"sources":["webpack://./src/app/team-section/team-section.component.scss"],"names":[],"mappings":"AAAA;EACE,iBAAA;EACA,kBAAA;EACA,aAAA;EACA,iBAAA;AACF;;AAEA;EACE,eAAA;EACA,kBAAA;EACA,WAAA;AACF;;AAEA;EACE,gBAAA;EACA,gBAAA;AACF;AACE;EACE,aAAA;AACJ;;AAGA;EACE,mBAAA;AAAF;;AAGA;EACE;IACE,YAAA;EAAF;EAGA;IACE,UAAA;EADF;EAIA;IACE,YAAA;EAFF;AACF","sourcesContent":[".container {\r\n  margin-left: auto;\r\n  margin-right: auto;\r\n  padding: 90px;\r\n  padding-top: 18vh;\r\n}\r\n\r\n.card {\r\n  cursor: pointer;\r\n  position: relative;\r\n  width: 100%;\r\n}\r\n\r\n.imgContainer {\r\n  max-height: 50vh;\r\n  overflow: hidden;\r\n\r\n  img {\r\n    padding: 50px;\r\n  }\r\n}\r\n\r\n.styledH2 {\r\n  margin-bottom: 20px;\r\n}\r\n\r\n@media screen and (max-width:768px) {\r\n  .container {\r\n    padding: 0px;\r\n  }\r\n\r\n  .container-fluid {\r\n    padding: 0;\r\n  }\r\n\r\n  img {\r\n    height: auto;\r\n  }\r\n\r\n}\r\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
