import { Directive, Input, ElementRef } from "@angular/core";
import { SettingsService } from "./settings.service";
import { ProjectParamsService } from "./project-params.service";

@Directive({
  selector: "[UserStyling]"
})
export class UserStylingDirective {
  @Input("backgroundImg") backgroundImg: string;
  element: any;

  constructor(
    el: ElementRef,
    private settings: SettingsService,
    private projectParams: ProjectParamsService
  ) {
    this.element = el.nativeElement;
  }

  ngOnInit() {
    const site = this.settings.getSetting("defaultSite");
    this.projectParams.getParams(site).then(settings => {
      const publicUrl = this.settings.getSetting("publicUrl");
      let fileName = this.backgroundImg;
      if(fileName === undefined){
        fileName = settings["userPageBgImg"]
      }
      const url = `${publicUrl}/img/${fileName}`;
      this.element.style.backgroundImage = `url('${url}')`;
    });
  }
}
