// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `a {
  text-decoration: none;
  color: #009688 !important;
}

h1 {
  display: block;
  font-family: "Crimson Text", monospace;
  font-size: 15vh;
  color: #009688 !important;
}

@media screen and (max-width: 1044px) {
  h1 {
    font-size: 5em;
  }
}
@media screen and (min-width: 1044px) {
  .logospace {
    margin-top: 25vh;
  }
}
.logospace {
  position: relative;
  top: 10vh;
}

@media screen and (max-width: 1043px) {
  .logospace {
    margin-top: 10vh;
  }
}
.centersubs {
  display: flex;
  justify-content: center;
  z-index: 999;
}

.subtitles {
  display: flex;
  justify-items: center;
  justify-content: space-between;
  margin-top: 10vh;
  font-size: 1.5em;
}`, "",{"version":3,"sources":["webpack://./src/app/cover/cover.component.scss"],"names":[],"mappings":"AAGK;EACE,qBAAA;EACA,yBAAA;AAFP;;AAKK;EACC,cAAA;EACC,sCAAA;EACA,eAAA;EACD,yBAAA;AAFN;;AAKK;EACE;IACE,cAAA;EAFP;AACF;AAMK;EACA;IACC,gBAAA;EAJJ;AACF;AAOI;EACE,kBAAA;EACA,SAAA;AALN;;AAOI;EACE;IACC,gBAAA;EAJL;AACF;AAOI;EACE,aAAA;EACA,uBAAA;EACA,YAAA;AALN;;AAQI;EACE,aAAA;EACA,qBAAA;EACA,8BAAA;EACA,gBAAA;EACA,gBAAA;AALN","sourcesContent":["     @import '../../variables.scss';\r\n\r\n\r\n     a {\r\n       text-decoration: none;\r\n       color: #009688 !important;\r\n     }\r\n\r\n     h1 {\r\n      display: block;\r\n       font-family: 'Crimson Text', monospace;\r\n       font-size: 15vh;\r\n      color: #009688 !important;\r\n     }\r\n\r\n     @media screen and (max-width:1044px) {\r\n       h1 {\r\n         font-size: 5em;\r\n       }\r\n\r\n     }\r\n\r\n     @media screen and (min-width:1044px) {\r\n     .logospace {\r\n      margin-top: 25vh;\r\n    }\r\n  }\r\n\r\n    .logospace{\r\n      position: relative;\r\n      top:10vh;\r\n    }\r\n    @media screen and (max-width:1043px) {\r\n      .logospace {\r\n       margin-top: 10vh;\r\n     }\r\n    }\r\n    \r\n    .centersubs{\r\n      display: flex;\r\n      justify-content: center;\r\n      z-index: 999;\r\n    }\r\n\r\n    .subtitles{\r\n      display: flex;\r\n      justify-items: center;\r\n      justify-content: space-between;\r\n      margin-top: 10vh;\r\n      font-size:1.5em;\r\n    }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
