import { Injectable } from '@angular/core';
import * as environment from '../environments/environment';

@Injectable({
  providedIn: 'root'
})
export class SettingsService {
  settingsSet: string[];
  constructor() {
    this.settingsSet = [];
    this.settingsSet['apiUrl'] = environment.environment.apiUrl;
    this.settingsSet['publicUrl'] = environment.environment.publicUrl;
    this.settingsSet['twitterScreenName'] = 'pengellab';
    this.settingsSet['defaultSite'] = 'engellab';
  }

  getSetting(key) {
    return this.settingsSet[key];
  }
}
