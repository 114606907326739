import { Component, OnInit, Output, EventEmitter, Input } from '@angular/core';
import * as $ from 'jquery';
import { SettingsService } from '../settings.service';
import axios from 'axios';
import * as _ from 'lodash';
import { ProjectParamsService } from '../project-params.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'projects-section',
  templateUrl: './projects-section.component.html',
  styleUrls: ['./projects-section.component.scss']
})
export class ProjectsSectionComponent implements OnInit {
  // tslint:disable-next-line: no-output-on-prefix
  @Output() onProjectsLoaded = new EventEmitter();
  imgWidth = 500;
  imgHeight = 200;
  apiUrl: string;
  projects: any;
  publicUrl: string;
  teamImg: string;
  projectsIntroduction: any;
  @Input() shortcutsTitle: string;
  bColor1: any;
  bColor2: any;
  bColor9: any;
  fColor1: any;
  fColor2: any;
  projectRows = [];

  constructor(private settings: SettingsService, private projectParams: ProjectParamsService) {
    const context = this;
    this.apiUrl = this.settings.getSetting('apiUrl');
    this.publicUrl = this.settings.getSetting('publicUrl');
    const defaultProject = this.settings.getSetting('defaultSite');
    this.projectParams.getParams(defaultProject).then(s => {
      this.bColor1 = s['bColor1'];
      this.fColor1 = s['fColor1'];
      this.bColor2 = s['bColor2'];
      this.fColor2 = s['fColor2'];
      this.bColor9 = s['bColor9'];
    })
    axios
      .get(this.apiUrl + '/projectParams')
      .then(d => {
        // emit valid projects instead of all available in db (non-hidden...)
        this.onProjectsLoaded.emit(d.data.length > 0 ? true : false);
        this.projects = d.data;
        this.projects = _.map(this.projects, o => {
          if (o.main) {
            return undefined;
          } else {
            o['img'] = this.publicUrl + '/img/' + o.overviewImg;
            if (o.externalUrl) {
              o['url'] = o.externalUrl;
            } else {
              o['url'] = '/project/' + o.project;
            }
            return o;
          }
        });
        context.projects = _.without(this.projects, undefined);
        context.projects = _.orderBy(this.projects, 'sortOrder', 'asc');

        let i = 0;
        let r = 0;
        let p = context.projects;
        let rLen = 0;
        let total = context.projects.length;

        while (i < total) {
          context.projectRows.push([]);
          while (rLen < 2) {
            context.projectRows[r].push(context.projects[i]);
            i++
            rLen++;
          }
          rLen = 0;
          r++;
          // context.projectRows[1] = this.projects.slice(halfwayThrough, this.projects.length);
        }
        context.setCss()

      })
      .catch(err => {
        console.log('Getting projects failed', err, this.apiUrl + '/projects');
      });
  }

  ngOnInit() {
  }

  setCss() {
    const context = this;
    setTimeout(() => {
      const height = 50 / context.projectRows.length;
      $('article .imgContainer').css('height', `${height}vh`)
    }, 1000)
  }

  test() {
    this.projectRows.push(this.projectRows[0])
    this.setCss()
  }

  menutoggle() {
    $('#menu')
      .animate({ height: '80px' }, 500)
      .show();
  }
}
