import { Component, OnInit, Input } from '@angular/core';
import { SettingsService } from '../settings.service';
import { ProjectParamsService } from '../project-params.service';

@Component({
  // tslint:disable-next-line: component-selector
  selector: 'team-section',
  templateUrl: './team-section.component.html',
  styleUrls: ['./team-section.component.scss']
})
export class TeamSectionComponent implements OnInit {
  @Input() teamImg: string;
  @Input() allPeople: any;
  teamImgUrl: any;
  teamSectionText: string;
  settingsService: any;
  bColor6: any;
  fColor6: any;
  constructor(private settings: SettingsService, private projectParams: ProjectParamsService) {
    const defaultProject = this.settings.getSetting('defaultSite');
    this.projectParams.getParams(defaultProject).then(s => {
      this.teamSectionText = s['teamSectionText'];
      this.bColor6 = s['bColor6'];
      this.fColor6 = s['fColor6'];
    });

  }

  ngOnInit() {
    this.teamImgUrl = this.settings.getSetting('publicUrl') + '/img/' + this.teamImg;
  }

  goToTeam() {
    location.href = '/team';
  }

}
