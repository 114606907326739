import { Directive, ElementRef, Input } from "@angular/core";
import { SettingsService } from "./settings.service";
import { ProjectParamsService } from "./project-params.service";

@Directive({
  selector: '[PageSyling]'
})
export class PageSylingDirective {
  @Input("backgroundImg") backgroundImg: string;
  element: any;
  constructor(
    el: ElementRef,
    private settings: SettingsService,
    private projectParams: ProjectParamsService
  ) {
    this.element = el.nativeElement;
  }
  ngOnInit() {
    const site = this.settings.getSetting("defaultSite");
    this.projectParams.getParams(site).then(settings => {

      const publicUrl = this.settings.getSetting("publicUrl");
      const fileName = this.backgroundImg;
      const url = `${publicUrl}/img/${fileName}`;
      this.element.style.backgroundImage = `url('${url}')`;
    }).catch(e => {
      console.error(e);
    });
  }
}
