import { Injectable } from "@angular/core";
import axios from "axios";
import { SettingsService } from "./settings.service";
import * as _ from "lodash";

@Injectable({
  providedIn: "root"
})
export class TeamService {
  publicUrl: any;
  peopleData: any[];
  people: any[];
  allPeople: any[];
  apiUrl: string;
  cols: any;

  constructor(private settings: SettingsService) { }

  sortPeople() {
    this.peopleData = _.map(this.peopleData, person => {
      if (person.picture) {
        let img = person.picture.filename;
        person["imgUrl"] = `${this.publicUrl}/img/${img}`;
      }
      if (person.thesisImg) {
        person["thesisImgUrl"] = `${this.publicUrl}/img/${person.thesisImg}`;
      }

      if (person.show == false) {
        return null;
      } else {
        return person;
      }
    });
    this.peopleData = _.without(this.peopleData, null);
    this.allPeople = this.peopleData;
    let cols = this.cols;
    let bootstrapCols = [];
    for (let i = 0; i < cols; i++) {
      bootstrapCols.push([]);
    }
    _.map(this.peopleData, (person, i) => {
      let rest = i % cols;
      bootstrapCols[rest].push(person);
    });
    this.people = bootstrapCols;
  }

  getPeopleData(cols) {
    return new Promise<any>((resolve, reject) => {
      this.cols = cols;
      this.apiUrl = this.settings.getSetting("apiUrl");
      this.publicUrl = this.settings.getSetting("publicUrl");
      axios
        .get(this.apiUrl + "/people")
        .then(d => {
          this.peopleData = d.data;
          this.sortPeople();
          resolve({ people: this.people, allPeople: this.allPeople });
        })
        .catch(err => {
          console.log("Getting collection failed", err);
          reject("Getting collection failed:" + err);
        });
    });
  }
}
